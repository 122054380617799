import { makeStyles } from "@fluentui/react-components";
import { PropsWithChildren } from "react";
import { useFlexBoxStyles } from "../rootStyles";

const useStyles = makeStyles({
  verticalStack: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    width: "auto",
    height: "auto",
    boxSizing: "border-box",
    "> *": {
      textOverflow: "ellipsis",
    },
    "> :not(:first-child)": {
      marginTop: "0px",
    },
    "> *:not(.ms-StackItem)": {
      flexShrink: 1,
    },
  },
  horizontalStack: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "auto",
    height: "auto",
    boxSizing: "border-box",
    "> *": {
      textOverflow: "ellipsis",
    },
    "> :not(:first-child)": {
      marginLeft: "0px",
    },
    "> *:not(.ms-StackItem)": {
      flexShrink: 1,
    },
  },
});

export function Stack(props: PropsWithChildren<{ horizontal?: boolean; className?: string; gap?: 10 | 20 }>): JSX.Element {
  const styles = useStyles();
  const flexBoxStyles = useFlexBoxStyles();

  const classNames = [];
  if (props.horizontal) {
    classNames.push(styles.horizontalStack);
  } else {
    classNames.push(styles.verticalStack);
  }

  if (props.className) {
    classNames.push(props.className);
  }

  if (props.gap) {
    classNames.push(flexBoxStyles[`gap${props.gap}`]);
  }

  return (
    <>
      <div className={classNames.join(" ")}>{props.children}</div>
    </>
  );
}
