import {
  AccountInfo,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig } from "./msalConfig";

export function getMsalInstance(): PublicClientApplication {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event: EventMessage) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS &&
      (event.payload as AccountInfo)?.homeAccountId
    ) {
      const account = event.payload as AccountInfo;
      msalInstance.setActiveAccount(account);
    }
  });

  return msalInstance;
}
