// SearchBar.tsx
import { Input, makeStyles } from '@fluentui/react-components';
import React, { useState } from 'react';

interface SearchBarProps {
    onSearch: (searchTerm: string) => void;
}

const useStyles = makeStyles({
    searchBarStyle: { marginBottom: "20px", padding: "10px" }
});

export const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [search, setSearch] = useState('');
  const styles = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearch = event.target.value;
    setSearch(newSearch);
    onSearch(newSearch);
  };

  return (
    <Input
      type="text"
      placeholder="Zoeken..."
      value={search}
      onChange={handleChange}
      className={styles.searchBarStyle}
    />
  );
};

