import { Drawer, DrawerHeader, DrawerHeaderTitle, Button, DrawerBody, Field, Input, DrawerFooter } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { useState } from "react";
import { Stack } from "./Stack";

interface IFileListCustomizePanel {
  show: boolean;
  siteUrl: string;
  documentSetName: string;
  onClose: (newSiteUrl?: string, newDocumentSetName?: string) => void;
}

export const FileListCustomizePanel = ({ show, siteUrl, documentSetName, onClose }: IFileListCustomizePanel) => {
  const [siteUrlFieldValue, setSiteUrlFieldValue] = useState<string>(siteUrl);
  const [docSetFieldValue, setDocSetFieldValue] = useState<string>(documentSetName);

  const closePanel = (newSiteUrl?: string, newDocumentSetName?: string) => {
    if (newSiteUrl === undefined && newDocumentSetName === undefined) {
      onClose();
    } else {
      onClose(newSiteUrl, newDocumentSetName);
    }

    setSiteUrlFieldValue(siteUrl);
    setDocSetFieldValue(documentSetName);
  };

  const submit = () => {
    closePanel(siteUrlFieldValue, docSetFieldValue);
  };

  return (
    <>
      <Drawer separator open={show} onOpenChange={() => closePanel()} position="end" size="medium">
        <DrawerHeader>
          <DrawerHeaderTitle action={<Button appearance="subtle" icon={<Dismiss24Regular />} onClick={() => closePanel()} />}>Customiseren</DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <Stack gap={10}>
            <p>Met het onderstaande invoerveld kan er gekozen worden om een alternatieve site URL uit te proberen om te kijken hoe het API reageert.</p>
            <Field required={true} label="Site Url">
              <Input required={true} autoComplete="off" type="url" defaultValue={siteUrlFieldValue} onChange={(_, data) => setSiteUrlFieldValue(data.value)} />
            </Field>
            <Field required label="Naam documentenset">
              <Input required autoComplete="off" type="text" defaultValue={docSetFieldValue} onChange={(_, data) => setDocSetFieldValue(data.value)} />
            </Field>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <Button appearance="primary" size="large" onClick={() => submit()}>
            Verzenden
          </Button>
          <Button onClick={() => closePanel()} size="large">
            Annuleren
          </Button>
        </DrawerFooter>
      </Drawer>
    </>
  );
};
