import { Configuration, LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: appSettings.EntraId.ClientId,
    authority: `https://login.microsoftonline.com/${appSettings.EntraId.TenantId}`, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: "/",
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    clientCapabilities: ["CP1"], // this lets the resource owner know that this client is capable of handling claims challenge.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      /**
       * Below you can configure MSAL.js logs. For more information, visit:
       * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
       */
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  scopes: {
    access_as_user: [`${appSettings.EntraId.AppIdUri}/access_as_user`],
  },
  sitesEndpoint: `${appSettings.EntraId.ApiBaseUrl ?? window.location.origin}/api/v1/werkomgevingen`,
  documentSetsEndpoint: `${appSettings.EntraId.ApiBaseUrl ?? window.location.origin}/api/v1/documentensets`,
  bedrijfsprocessEndpoint: `${appSettings.EntraId.ApiBaseUrl ?? window.location.origin}/api/v1/bedrijfsprocessen`,
  activityEndpoint: `${appSettings.EntraId.ApiBaseUrl ?? window.location.origin}/api/v1/wbs-activiteiten`,
  dossierTypeEndpoint: `${appSettings.EntraId.ApiBaseUrl ?? window.location.origin}/api/v1/dossiertypen`,
  documentTypeEndpoint: `${appSettings.EntraId.ApiBaseUrl ?? window.location.origin}/api/v1/documenttypen`,
  organisatieEndpoint: `${appSettings.EntraId.ApiBaseUrl ?? window.location.origin}/api/v1/organisatieonderdelen`,
  aanbestedingEndpoint: `${appSettings.EntraId.ApiBaseUrl ?? window.location.origin}/api/v1/aanbestedingstypen`,
  toezichtEndpoint: `${appSettings.EntraId.ApiBaseUrl ?? window.location.origin}/api/v1/soort-toezicht`,
  filesEndpoint: `${appSettings.EntraId.ApiBaseUrl ?? window.location.origin}/api/v1/bestanden`,
  fileEndpoint: `${appSettings.EntraId.ApiBaseUrl ?? window.location.origin}/api/v1/bestanden/inhoud`,
  koppelingEndpoint: `${appSettings.EntraId.ApiBaseUrl ?? window.location.origin}/api/v1/koppelingen`,
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [...protectedResources.scopes.access_as_user],
};
