import { Drawer, DrawerHeader, DrawerHeaderTitle, Button, DrawerBody, Field, Input, Checkbox, Combobox, OptionGroup, Option, DrawerFooter, InfoLabel, Spinner } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { Stack } from "./Stack";
import { useFetchWithMsal } from "../hooks/useFetchWithMsal";
import { protectedResources } from "../msalConfig";
import { ApiError, LinkForm, LinkPayload } from "../types/endpoints";
import { SpinnerButton } from "./SpinnerButton";
import { ApiErrorMessageBar } from "./ApiErrorMessageBar";

const relatedApplications = ["Anders - Overige", "ContractX", "iBabs", "Kofax", "M365", "Melvin", "Meridian", "OpenWave", "P8", "Pleio", "Relatics", "SAP", "SharePoint", "Tenderned", "Verseon", "VISI", "Xential"];
const bestandsnaamInfo = "De naam van de koppeling in SharePoint.";

interface ILinkCreationPanel {
  show: boolean;
  siteUrl: string;
  documentSetName: string;
  onClose: (createdLink?: LinkForm) => void;
}

export const LinkCreationPanel = ({ siteUrl, documentSetName, show, onClose }: ILinkCreationPanel) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [documentTypeList, setDocumentTypeList] = useState<string[]>([]);
  const [enablePassedInFields, setEnablePassedInFields] = useState(false);
  const [titleFieldValue, setTitleFieldValue] = useState("");
  const [referenceFieldValue, setReferenceFieldValue] = useState("");
  const [docSetFieldValue, setDocSetFieldValue] = useState(documentSetName);
  const [siteUrlFieldValue, setSiteUrlFieldValue] = useState(siteUrl);
  const [relatedAppFieldValue, setRelatedAppFieldValue] = useState("");
  const [linkFieldValue, setLinkFieldValue] = useState("");
  const [fileNameFieldValue, setFileNameFieldValue] = useState("");
  const [documentTypeFieldValue, setDocumentTypeFieldValue] = useState("");
  const [error, setError] = useState<ApiError | undefined>();

  const { execute, isLoading } = useFetchWithMsal({
    scopes: protectedResources.scopes.access_as_user,
  });

  const closePanel = (createdLink?: LinkForm) => {
    setTitleFieldValue("");
    setSiteUrlFieldValue(siteUrl);
    setDocSetFieldValue(documentSetName);
    setReferenceFieldValue("");
    setRelatedAppFieldValue("");
    setLinkFieldValue("");
    setFileNameFieldValue("");
    setEnablePassedInFields(false);
    setDocumentTypeFieldValue("");
    setError(undefined);
    onClose(createdLink);
  };

  const submit = async (): Promise<void> => {
    setError(undefined);

    let payloadEmpty = true;
    const payload: LinkPayload = {};
    const fields: LinkPayload = {
      siteUrl: siteUrlFieldValue,
      naam: fileNameFieldValue,
      titel: titleFieldValue,
      koppelingUrl: linkFieldValue,
      documentenset: docSetFieldValue,
      gerelateerdeApplicatie: relatedAppFieldValue,
      documenttype: documentTypeFieldValue,
      kenmerk: referenceFieldValue,
    };

    (Object.entries(fields) as [keyof LinkPayload, any][]).forEach(([key, value]) => {
      if (value) {
        payload[key] = value;
        payloadEmpty = false;
      }
    });

    try {
      const createdLink = await execute("POST", protectedResources.koppelingEndpoint, !payloadEmpty ? payload : null);
      closePanel(createdLink);
    } catch (error: any) {
      setError(error.cause);
    }
  };

  useEffect(() => {
    if (show && !isDataFetched) {
      const fetchData = async () => {
        setIsFetching(true);
        try {
          const endpoints: { url: string; setter: (data: any) => void }[] = [
            {
              url: `${protectedResources.documentTypeEndpoint}`,
              setter: setDocumentTypeList,
            },
          ];

          const getAPIQueue = async (endpoints: { url: string; setter: (data: any) => void }[]) => {
            for (let i = 0; i < endpoints.length; i++) {
              const { url, setter } = endpoints[i];
              try {
                const response = await execute("GET", url);
                setter(response);
              } catch (error: any) {
                console.error(error.cause);
              }
            }
          };

          await getAPIQueue(endpoints);
          setIsDataFetched(true);
        } catch (error) {
          console.error(error);
        } finally {
          setIsFetching(false);
        }
      };

      fetchData();
    }
  }, [execute, isDataFetched, show]);

  return (
    <Drawer separator open={show} onOpenChange={() => closePanel()} position="end" size="medium">
      <DrawerHeader>
        <DrawerHeaderTitle action={<Button appearance="subtle" icon={<Dismiss24Regular />} onClick={() => closePanel()} />}>Koppeling aanmaken</DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody>
        {isFetching ? (
          <Spinner size="large" />
        ) : (
          <Stack gap={10}>
            {error ? <ApiErrorMessageBar apiError={error} /> : <></>}
            <Checkbox label="Velden met standaardwaarden inschakelen" defaultChecked={enablePassedInFields} onChange={(_, data) => setEnablePassedInFields(data.checked === true)} />

            <Field required label="Koppeling">
              <Input required type="url" onChange={(ev) => setLinkFieldValue(ev.target.value)} disabled={isLoading} />
            </Field>

            <Field required label={<InfoLabel info={bestandsnaamInfo}>Bestandsnaam</InfoLabel>}>
              <Input required type="text" value={fileNameFieldValue} onChange={(ev) => setFileNameFieldValue(ev.target.value)} disabled={isLoading} />
            </Field>

            <Field required label="Titel">
              <Input required autoComplete="off" type="text" value={titleFieldValue} onChange={(ev) => setTitleFieldValue(ev.target.value)} disabled={isLoading} />
            </Field>

            <Field required label="Naam documentenset">
              <Input required autoComplete="off" type="text" disabled={!enablePassedInFields || isLoading} value={docSetFieldValue} onChange={(ev) => setDocSetFieldValue(ev.target.value)} />
            </Field>

            <Field required label="Site Url">
              <Input required autoComplete="off" type="url" disabled={!enablePassedInFields || isLoading} value={siteUrlFieldValue} onChange={(ev) => setSiteUrlFieldValue(ev.target.value)} />
            </Field>

            <Field required label="Gerelateerde applicatie">
              <Combobox required autoComplete="off" freeform onOptionSelect={(_, data) => setRelatedAppFieldValue(data.optionText || "")} onChange={(ev) => setRelatedAppFieldValue(ev.target.value)} placeholder="Selecteer applicatie" value={relatedAppFieldValue} disabled={isLoading}>
                <OptionGroup>
                  {relatedApplications.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </OptionGroup>
              </Combobox>
            </Field>

            <Field required label="Kenmerk">
              <Input required autoComplete="off" type="text" value={referenceFieldValue} onChange={(ev) => setReferenceFieldValue(ev.target.value)} disabled={isLoading} />
            </Field>

            <Field label="Documenttype">
              <Combobox freeform autoComplete="off" value={documentTypeFieldValue} onChange={(ev) => setDocumentTypeFieldValue(ev.target.value)} onOptionSelect={(_, data) => setDocumentTypeFieldValue(data.optionText || "")} placeholder="Selecteer documenttype" disabled={isLoading}>
                <OptionGroup>
                  {documentTypeList.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </OptionGroup>
              </Combobox>
            </Field>
          </Stack>
        )}
      </DrawerBody>
      <DrawerFooter>
        <SpinnerButton text="Aanmaken" buttonProps={{ appearance: "primary", size: "large" }} textWhileLoading="Bezig met aanmaken" onClick={submit} />
        <Button onClick={() => closePanel()} size="large" disabled={isLoading}>
          Annuleren
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
