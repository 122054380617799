import {
  Button,
  ButtonProps,
  Spinner,
  makeStyles,
  tokens,
  buttonClassNames
} from "@fluentui/react-components";
import { CheckmarkFilled } from "@fluentui/react-icons";
import { useState } from "react";

export type ISpinnerButtonProps = {
  isLoading?: boolean;
  buttonProps?: ButtonProps;
  onClick: () => Promise<void>;
  text: string;
  textWhileLoading: string;
  textDoneLoading?: string;
  icon?: JSX.Element;
}

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
  },
  buttonNonInteractive: {
    backgroundColor: tokens.colorNeutralBackground1,
    border: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
    color: tokens.colorNeutralForeground1,
    cursor: "default",
    pointerEvents: "none",

    [`& .${buttonClassNames.icon}`]: {
      color: tokens.colorStatusSuccessForeground1,
    },
  },
});

type LoadingState = "initial" | "loading" | "loaded";

export const SpinnerButton = (
  props: ISpinnerButtonProps
): JSX.Element => {
  const { text, textWhileLoading, textDoneLoading, onClick, buttonProps, icon } = props;

  const styles = useStyles();

  const [loadingState, setLoadingState] =
    useState<LoadingState>("initial");

  const onButtonClick = async () => {
    setLoadingState("loading");
    await onClick();
    setLoadingState("loaded");
  };

  const buttonContent =
    loadingState === "loading"
      ? textWhileLoading
      : loadingState === "loaded"
      ? (textDoneLoading || text)
      : text;

  const buttonIcon =
    loadingState === "loading" ? (
      <Spinner size="tiny" />
    ) : loadingState === "loaded" && textDoneLoading !== undefined ? (
      <CheckmarkFilled />
    ) : icon || null;

  const buttonClassName =
    loadingState === "initial" ? undefined : loadingState === "loaded" && textDoneLoading === undefined ? undefined : styles.buttonNonInteractive;


  return (
    <Button {...buttonProps} className={buttonClassName} icon={buttonIcon} onClick={onButtonClick} disabledFocusable={loadingState === "loading"}>
      {buttonContent}
    </Button>
  );
};
