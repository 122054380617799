import { Site } from "../types/endpoints";
import { MessageBar, MessageBarBody, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchBar } from "./SearchBar";

const columns = [
  { columnKey: "naam", label: "Naam" },
  { columnKey: "url", label: "URL" },
];

interface ISiteProps {
  items: Site[];
}

export const SiteList = (props: ISiteProps) => {
  const navigate = useNavigate();
  const { items } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState<Site[]>(items);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredItems(items);
      return;
    }

    const lowercasedTerm = searchTerm.toLowerCase();
    const filtered = items.filter((item) => item.naam.toLowerCase().includes(lowercasedTerm) || item.url.toLowerCase().includes(lowercasedTerm));
    setFilteredItems(filtered);
  }, [searchTerm, items]);

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  return (
    <>
      <SearchBar onSearch={handleSearch} />
      {filteredItems.length > 0 ? (
        <Table>
          <TableHeader>
            <TableRow>
              {columns.map((column) => (
                <TableHeaderCell key={column.columnKey}>{column.label}</TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredItems.map((item, index) => (
              <TableRow key={index} onClick={() => navigate("/testen/documentensets", { state: { site: item } })}>
                <TableCell>
                  <TableCellLayout truncate>{item.naam}</TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout truncate>{item.url}</TableCellLayout>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <MessageBar>
          <MessageBarBody>Geen items gevonden</MessageBarBody>
        </MessageBar>
      )}
    </>
  );
};
