import React, { useCallback, useEffect, useState } from "react";
import { Button, DrawerHeader, DrawerBody, DrawerHeaderTitle, Field, Input, makeStyles, shorthands, Radio, RadioGroup, tokens, Combobox, Option, DrawerFooter, Checkbox, Spinner, RadioGroupOnChangeData } from "@fluentui/react-components";
import { DrawerOverlay } from "@fluentui/react-components/unstable";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { protectedResources } from "../msalConfig";
import { useFetchWithMsal } from "../hooks/useFetchWithMsal";
import { BusinessProcess, WBSActivity, OrganisatieOnderdeel, DocumentSetItem, DocumentSetPayload, ApiError } from "../types/endpoints";
import { SpinnerButton } from "./SpinnerButton";
import { useSizeStyles } from "../rootStyles";
import { ApiErrorMessageBar } from "./ApiErrorMessageBar";

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    gap: "2px",
    maxWidth: "400px",
  },
  content: {
    ...shorthands.flex(1),
    ...shorthands.padding("16px"),
    display: "grid",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gridRowGap: tokens.spacingVerticalXXL,
    gridAutoRows: "max-content",
  },
  field: {
    display: "grid",
    gridRowGap: tokens.spacingVerticalS,
  },
  buttonContainer: {
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  buttonfield: {
    marginBottom: tokens.spacingVerticalL,
    marginTop: tokens.spacingVerticalL,
  },
});

interface IDocumentSetCreationPanelProps {
  siteUrl: string;
  isOpen: boolean;
  onClose: (newDocumentSet?: DocumentSetItem) => void;
}

export const DocumentSetCreationPanel: React.FC<IDocumentSetCreationPanelProps> = ({ siteUrl, isOpen, onClose }) => {
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.scopes.access_as_user,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [businessProcessList, setBusinessProcessList] = useState<BusinessProcess[] | undefined>();
  const [wbsActivityList, setWbsActivityList] = useState<WBSActivity[] | undefined>();
  const [contentTypeList, setContentTypeList] = useState<string[]>([]);
  const [organisationSectionList, setOrganisationSectionList] = useState<OrganisatieOnderdeel[] | undefined>();
  const [procurementTypeList, setProcurementTypeList] = useState<string[]>([]);
  const [toezichtSoort, setToezichtSoort] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>("bedrijfsproces");
  const [isProjectRequired, setIsProjectRequired] = useState(false);
  const [isProcurementTypeRequired, setIsProcurementTypeRequired] = useState(false);
  const [isSupervisionTypeRequired, setIsSupervisionTypeRequired] = useState(false);
  const [titleFieldValue, setTitleFieldValue] = useState<string>("");
  const [siteUrlFieldValue, setSiteUrlFieldValue] = useState<string>(siteUrl);
  const [nameFieldValue, setNameFieldValue] = useState<string>("");
  const [projectFieldValue, setProjectFieldValue] = useState<string>("");
  const [referenceFieldValue, setReferenceFieldValue] = useState<string>("");
  const [procurementTypeFieldValue, setProcurementTypeFieldValue] = useState<string>("");
  const [supervisionTypeFieldValue, setSupervisionTypeFieldValue] = useState<string>("");
  const [businessProcessFieldValue, setBusinessProcessFieldValue] = useState<string>("");
  const [wbsActivityFieldValue, setWbsActivityFieldValue] = useState<string>("");
  const [relatedAppFieldValue, setRelatedAppFieldValue] = useState<string>("");
  const [organisationSectionFieldValue, setOrganisationSectionFieldValue] = useState<string>("");
  const [contentTypeFieldValue, setContentTypeFieldValue] = useState<string>("");
  const [submitError, setSubmitError] = useState<ApiError | undefined>();
  const [enablePassedInFields, setEnablePassedInFields] = useState<boolean>(false);

  const gerelateerdeApplicatie = ["Anders - Overige", "ContractX", "iBabs", "Kofax", "M365", "Melvin", "Meridian", "OpenWave", "P8", "Pleio", "Relatics", "SAP", "SharePoint", "Tenderned", "Verseon", "VISI", "Xential"];

  const styles = useStyles();
  const rootstyle = useSizeStyles();

  const closePanel = (newDocumentSet?: DocumentSetItem) => {
    setEnablePassedInFields(false);
    setSubmitError(undefined);
    resetForm();

    onClose(newDocumentSet);
  };

  const handleRadioChange = (ev: React.FormEvent<HTMLDivElement>, e: RadioGroupOnChangeData) => {
    const value = e.value;
    setSelectedOption(value);
    if (value === "bedrijfsproces") {
      setBusinessProcessFieldValue("");
    } else if (value === "activiteit") {
      setWbsActivityFieldValue("");
    }
  };

  const handleInhoudstypeChange = (selectedOption: string) => {
    if (selectedOption) {
      setContentTypeFieldValue(selectedOption);
      setIsProcurementTypeRequired(selectedOption === "Aanbesteding");
      setIsProjectRequired(selectedOption === "Infrastructuur");
      setIsSupervisionTypeRequired(selectedOption === "Interbestuurlijk Toezicht");
    }
    if (selectedOption !== "Interbestuurlijk Toezicht") {
      setSupervisionTypeFieldValue("");
    }
    if (selectedOption !== "Aanbesteding") {
      setProcurementTypeFieldValue("");
    }
  };

  const resetForm = useCallback(() => {
    setSelectedOption("bedrijfsproces");
    setIsProjectRequired(false);
    setIsProcurementTypeRequired(false);
    setIsSupervisionTypeRequired(false);
    setTitleFieldValue("");
    setSiteUrlFieldValue(siteUrl);
    setNameFieldValue("");
    setProjectFieldValue("");
    setReferenceFieldValue("");
    setProcurementTypeFieldValue("");
    setSupervisionTypeFieldValue("");
    setBusinessProcessFieldValue("");
    setWbsActivityFieldValue("");
    setRelatedAppFieldValue("");
    setOrganisationSectionFieldValue("");
    setContentTypeFieldValue("");
    setSubmitError(undefined);
    setEnablePassedInFields(false);
  }, [siteUrl]);

  useEffect(() => {
    if (isOpen && !isDataFetched) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const endpoints: { url: string; setter: (data: any) => void }[] = [
            { url: `${protectedResources.dossierTypeEndpoint}`, setter: setContentTypeList },
            { url: `${protectedResources.bedrijfsprocessEndpoint}`, setter: setBusinessProcessList },
            { url: `${protectedResources.activityEndpoint}`, setter: setWbsActivityList },
            { url: `${protectedResources.organisatieEndpoint}`, setter: setOrganisationSectionList },
            { url: `${protectedResources.aanbestedingEndpoint}`, setter: setProcurementTypeList },
            { url: `${protectedResources.toezichtEndpoint}`, setter: setToezichtSoort },
          ];

          const getAPIQueue = async (endpoints: { url: string; setter: (data: any) => void }[]) => {
            for (let i = 0; i < endpoints.length; i++) {
              const { url, setter } = endpoints[i];
              try {
                const response = await execute("GET", url);
                setter(response);
              } catch (error: any) {
                console.error(error.cause);
              }
            }
          };

          await getAPIQueue(endpoints);
          setIsDataFetched(true);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else if (!isOpen) {
      resetForm();
    }
  }, [isOpen, execute, resetForm, isDataFetched]);

  const handleSubmit = async () => {
    setSubmitError(undefined);
    setIsSubmitting(true);

    let payloadEmpty = true;
    const payload: DocumentSetPayload = {};
    const fields: DocumentSetPayload = {
      siteUrl: siteUrlFieldValue,
      naam: nameFieldValue,
      titel: titleFieldValue,
      inhoudstype: contentTypeFieldValue,
      gerelateerdeApplicatie: relatedAppFieldValue,
      project: projectFieldValue,
      kenmerk: referenceFieldValue,
      typeAanbesteding: procurementTypeFieldValue,
      bedrijfsproces: businessProcessFieldValue,
      wbsActiviteit: wbsActivityFieldValue,
      organisatieonderdeel: organisationSectionFieldValue,
      toezichtssoort: supervisionTypeFieldValue,
    };

    (Object.entries(fields) as [keyof DocumentSetPayload, any][]).forEach(([key, value]) => {
      if (value) {
        payload[key] = value;
        payloadEmpty = false;
      }
    });

    try {
      const newDocumentSet = await execute("POST", `${protectedResources.documentSetsEndpoint}`, !payloadEmpty ? payload : undefined);
      closePanel(newDocumentSet);
    } catch (error: any) {
      setSubmitError(error.cause);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isOpen ? (
        <div className={styles.root}>
          <DrawerOverlay size="medium" open={isOpen} onOpenChange={(_, { open }) => onClose()} position="end">
            <DrawerHeader>
              <DrawerHeaderTitle action={<Button appearance="subtle" aria-label="Close" icon={<Dismiss24Regular />} onClick={() => onClose()} />}>Nieuwe documentenset</DrawerHeaderTitle>
              {submitError ? <ApiErrorMessageBar apiError={submitError} /> : <></> }
            </DrawerHeader>
            <DrawerBody>
              {isLoading ? (
                <Spinner size="large" />
              ) : (
                <div className={styles.field}>
                  <Checkbox label="Velden met standaardwaarden inschakelen" checked={enablePassedInFields} onChange={(_, data) => setEnablePassedInFields(data.checked === true)} disabled={isSubmitting} />

                  <Field required label="Naam">
                    <Input required autoComplete="off" value={nameFieldValue} onChange={(_, data) => setNameFieldValue(data.value)} disabled={isSubmitting} />
                  </Field>

                  <Field required label="Titel">
                    <Input required autoComplete="off" value={titleFieldValue} onChange={(_, data) => setTitleFieldValue(data.value)} disabled={isSubmitting} />
                  </Field>

                  <Field required label="Site Url">
                    <Input required autoComplete="off" type="url" disabled={!enablePassedInFields || isSubmitting} value={siteUrlFieldValue} onChange={(_, data) => setSiteUrlFieldValue(data.value)} />
                  </Field>

                  <Field required label="Inhoudstype">
                    <Combobox freeform placeholder="Selecteer inhoudstype" value={contentTypeFieldValue} onOptionSelect={(_, data) => handleInhoudstypeChange(data.optionText || "")} onChange={(event) => handleInhoudstypeChange(event.target.value)} disabled={isSubmitting}>
                      {contentTypeList.map((option, index) => (
                        <Option key={index} text={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Combobox>
                  </Field>

                  <Field label="Aanbestedingstype" required={isProcurementTypeRequired}>
                    <Combobox freeform value={procurementTypeFieldValue} placeholder="Selecteer aanbestedingstype" onOptionSelect={(_, data) => setProcurementTypeFieldValue(data.optionText || "")} onChange={(event) => setProcurementTypeFieldValue(event.target.value)} disabled={(contentTypeFieldValue !== "Aanbesteding" && !enablePassedInFields) || isSubmitting}>
                      {procurementTypeList.map((option, index) => (
                        <Option key={index} text={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Combobox>
                  </Field>

                  <Field label="Soort toezicht" required={isSupervisionTypeRequired}>
                    <Combobox freeform value={supervisionTypeFieldValue} placeholder="Selecteer soort toezicht" onOptionSelect={(_, data) => setSupervisionTypeFieldValue(data.optionText || "")} onChange={(event) => setSupervisionTypeFieldValue(event.target.value)} disabled={(contentTypeFieldValue !== "Interbestuurlijk Toezicht" && !enablePassedInFields) || isSubmitting}>
                      {toezichtSoort.map((option, index) => (
                        <Option key={index} text={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Combobox>
                  </Field>

                  <Field>
                    <RadioGroup layout="horizontal" value={selectedOption} onChange={handleRadioChange} disabled={isSubmitting}>
                      <Radio value="bedrijfsproces" label="Bedrijfsproces" />
                      <Radio value="activiteit" label="Activiteit" />
                    </RadioGroup>
                  </Field>

                  <Field label="Bedrijfsproces" required={selectedOption === "bedrijfsproces"}>
                    <Combobox freeform onOptionSelect={(_, data) => setBusinessProcessFieldValue(data.optionText || "")} disabled={(selectedOption === "activiteit" && !enablePassedInFields) || isSubmitting} placeholder="Selecteer bedrijfsproces" value={businessProcessFieldValue} onChange={(event) => setBusinessProcessFieldValue(event.target.value)}>
                      {businessProcessList &&
                        businessProcessList.map((process, index) => (
                          <Option key={index} value={process.toString()}>
                            {process.toString()}
                          </Option>
                        ))}
                    </Combobox>
                  </Field>

                  <Field label="WBS Activiteit" required={selectedOption === "activiteit"}>
                    <Combobox freeform onOptionSelect={(_, data) => setWbsActivityFieldValue(data.optionText || "")} autoComplete="off" required={selectedOption === "activiteit"} disabled={(selectedOption === "bedrijfsproces" && !enablePassedInFields) || isSubmitting} placeholder="Selecteer WBS activiteit" value={wbsActivityFieldValue} onChange={(event) => setWbsActivityFieldValue(event.target.value)}>
                      {wbsActivityList &&
                        wbsActivityList.map((item, index) => (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <Option disabled className={rootstyle.textUnderline}>
                                {item.fase}
                              </Option>
                            )}
                            {index !== 0 && wbsActivityList[index - 1].fase !== item.fase && (
                              <Option disabled className={rootstyle.textUnderline}>
                                {item.fase}
                              </Option>
                            )}
                            {index === 0 && (
                              <Option disabled className={rootstyle.marginLeft20}>
                                {item.rol}
                              </Option>
                            )}
                            {index !== 0 && wbsActivityList[index - 1].rol !== item.rol && (
                              <Option disabled className={rootstyle.marginLeft20}>
                                {item.rol}
                              </Option>
                            )}
                            <Option className={rootstyle.marginLeft40} value={item.activiteit}>
                              {item.activiteit}
                            </Option>
                          </React.Fragment>
                        ))}
                    </Combobox>
                  </Field>

                  <Field required={isProjectRequired} label="Project">
                    <Input required={isProjectRequired} autoComplete="off" value={projectFieldValue} onChange={(_, data) => setProjectFieldValue(data.value)} disabled={isSubmitting} />
                  </Field>

                  <Field required label="Gerelateerde applicatie">
                    <Combobox required freeform onOptionSelect={(_, data) => setRelatedAppFieldValue(data.optionText || "")} autoComplete="off" placeholder="Selecteer applicatie" value={relatedAppFieldValue} onChange={(event) => setRelatedAppFieldValue(event.target.value)} disabled={isSubmitting}>
                      {gerelateerdeApplicatie.map((option) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Combobox>
                  </Field>

                  <Field label="Organisatieonderdeel">
                    <Combobox onOptionSelect={(_, data) => setOrganisationSectionFieldValue(data.optionText || "")} freeform autoComplete="off" placeholder="Selecteer organisatieonderdeel" value={organisationSectionFieldValue} onChange={(event) => setOrganisationSectionFieldValue(event.target.value)} disabled={isSubmitting}>
                      {organisationSectionList &&
                        organisationSectionList.map((organisatieOnderdeel, index) => (
                          <React.Fragment key={index}>
                            <Option className={rootstyle.textUnderline} value={organisatieOnderdeel.titel}>
                              {organisatieOnderdeel.titel}
                            </Option>
                            {organisatieOnderdeel.sectoren &&
                              organisatieOnderdeel.sectoren.map((sector, sectorIndex) => (
                                <React.Fragment key={sectorIndex}>
                                  <Option className={rootstyle.marginLeft20} value={sector.titel}>
                                    {sector.titel}
                                  </Option>
                                  {sector.sectoren &&
                                    sector.sectoren.map((subsector, subsectorIndex) => (
                                      <React.Fragment key={subsectorIndex}>
                                        <Option className={rootstyle.marginLeft40} value={subsector.titel}>
                                          {subsector.titel}
                                        </Option>
                                      </React.Fragment>
                                    ))}
                                </React.Fragment>
                              ))}
                          </React.Fragment>
                        ))}
                    </Combobox>
                  </Field>

                  <Field label="Kenmerk" required>
                    <Input required autoComplete="off" placeholder="" value={referenceFieldValue} onChange={(_, data) => setReferenceFieldValue(data.value)} disabled={isSubmitting} />
                  </Field>
                </div>
              )}
            </DrawerBody>
            <DrawerFooter>
              <SpinnerButton text="Aanmaken" textWhileLoading="Bezig met aanmaken" buttonProps={{ size: "large", appearance: "primary", disabled: isSubmitting }} onClick={handleSubmit} />
              <Button onClick={() => onClose()} size="large" disabled={isSubmitting}>
                Annuleren
              </Button>
            </DrawerFooter>
          </DrawerOverlay>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
