import { DocumentSetItem } from "../types/endpoints";
import { MessageBar, MessageBarBody, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { useLocation, useNavigate } from "react-router-dom";

const columns = [
  { columnKey: "naam", label: "Naam" },
  { columnKey: "dossierType", label: "Dossier Type" },
  { columnKey: "bedrijfsproces", label: "Bedrijfsproces" },
  { columnKey: "wbsActiviteit", label: "WBS-activiteit" },
];

interface IDocumentSetListProps {
  items: DocumentSetItem[];
}

export const DocumentSetList = (props: IDocumentSetListProps) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { items } = props;

  const sortedItems = items.sort((a, b) => b.documentId.localeCompare(a.documentId));

  return sortedItems.length > 0 ? (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            {columns.map((column) => (
              <TableHeaderCell key={column.columnKey}>{column.label}</TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={index} onClick={() => navigate("/testen/bestanden", { state: { site: state.site, documentSet: item } })}>
              <TableCell>
                <TableCellLayout truncate>{item.naam}</TableCellLayout>
              </TableCell>
              <TableCell>
                <TableCellLayout truncate>{item.inhoudstype}</TableCellLayout>
              </TableCell>
              <TableCell>
                <TableCellLayout truncate>{item.bedrijfsproces}</TableCellLayout>
              </TableCell>
              <TableCell>
                <TableCellLayout truncate>{item.wbsActiviteit}</TableCellLayout>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  ) : (
    <>
      <MessageBar>
        <MessageBarBody>Geen items gevonden</MessageBarBody>
      </MessageBar>
    </>
  );
};
