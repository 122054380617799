import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Outlet } from "react-router-dom";
import { PageLayout } from "../components/PageLayout";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../msalConfig";

export default function Root() {
  const authRequest = { ...loginRequest };

  return (
    <>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
        <PageLayout>
          <Outlet />
        </PageLayout>
      </MsalAuthenticationTemplate>
    </>
  );
}
