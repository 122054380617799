import { Caption1, Card, CardHeader, CardPreview, makeStyles, tokens, Text } from "@fluentui/react-components";
import { Stack } from "../components/Stack";
import { useNavigate } from "react-router-dom";

/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },

  header: {
    marginBottom: "40px",
  },

  title: {
    marginBottom: "20px",
    marginTop: "0px",
  },

  main: {
    gap: "16px",
    display: "flex",
    flexWrap: "wrap",
  },

  card: {
    width: "400px",
    maxWidth: "100%",
    height: "fit-content",
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  smallRadius: { borderRadius: tokens.borderRadiusSmall },

  grayBackground: {
    backgroundColor: tokens.colorNeutralBackground3,
  },

  logoBadge: {
    padding: "5px",
    borderRadius: tokens.borderRadiusSmall,
    backgroundColor: "#FFF",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)",
  },
});

export const Home = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.container}>
        <Stack>
          <div className={styles.header}>
            <h1 className={styles.title}>Welkom bij het IOS API</h1>
            <Text weight="regular">Een API om te interacteren met IOS, document management systeem van de Provincie Noord-Holland.</Text>
          </div>

          <Stack horizontal gap={20}>
            <Card className={styles.card} onSelectionChange={() => navigate("/testen/werkomgevingen")}>
              <CardPreview className={styles.grayBackground} logo={<img className={styles.logoBadge} src="/logo30.jpg" alt="Card tester" />}>
                <img className={styles.smallRadius} src="/card-site.jpg" alt="Presentation Preview" />
              </CardPreview>

              <CardHeader header={<Text weight="semibold">IOS API Testen</Text>} description={<Caption1 className={styles.caption}>Selecteer een werkomgeving en test de interacties</Caption1>} />
            </Card>
            <Card className={styles.card} onSelectionChange={() => (window.location.href = "/swagger")}>
              <CardPreview className={styles.grayBackground} logo={<img className={styles.logoBadge} src="/swagger_logo30.jpg" alt="Card swagger" />}>
                <img className={styles.smallRadius} src="/card-swagger.jpg" alt="Presentation Preview" />
              </CardPreview>

              <CardHeader header={<Text weight="semibold">Swagger / OAS3</Text>} description={<Caption1 className={styles.caption}>Bekijk de OAS3 developer documentatie</Caption1>} />
            </Card>
          </Stack>
        </Stack>
      </div>
    </>
  );
};
