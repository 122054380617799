import { FluentProvider, RendererProvider, createDOMRenderer, webLightTheme } from "@fluentui/react-components";
import { MsalProvider } from "@azure/msal-react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Home } from "./routes/Home";
import { ErrorPage } from "./routes/ErrorPage";
import { DocumentSets } from "./routes/DocumentSets";
import { Files } from "./routes/Files";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useMemo } from "react";
import { Sites } from "./routes/Sites";
import Root from "./routes/Root";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/testen",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/testen/werkomgevingen",
        handle: {
          breadcrumbTitle: "Werkomgeving",
        },
        element: <Sites />,
      },
      {
        path: "/testen/documentensets",
        handle: {
          breadcrumbTitle: "Documentensets",
        },
        element: <DocumentSets />,
      },
      {
        path: "/testen/bestanden",
        handle: {
          breadcrumbTitle: "Bestanden",
        },
        element: <Files />,
      },
    ],
  },
]);

export const App = (props: { msalInstance: IPublicClientApplication; nonce: string }) => {
  const { nonce } = props;

  const renderer = useMemo(() => createDOMRenderer(document, { styleElementAttributes: { nonce: nonce } }), [nonce]);

  return (
    <>
      <RendererProvider renderer={renderer}>
        <FluentProvider theme={webLightTheme}>
          <MsalProvider instance={props.msalInstance}>
            <RouterProvider router={router} />
          </MsalProvider>
        </FluentProvider>
      </RendererProvider>
    </>
  );
};
