import { Stack } from "../components/Stack";
import { useFlexBoxStyles, useSizeStyles } from "../rootStyles";
import { useCallback, useEffect, useState } from "react";
import { SiteList } from "../components/SiteList";
import { Site } from "../types/endpoints";
import { protectedResources } from "../msalConfig";
import { useFetchWithMsal } from "../hooks/useFetchWithMsal";
import { Toolbar, ToolbarButton, MessageBar, MessageBarBody, Spinner } from "@fluentui/react-components";
import { ArrowClockwise16Regular } from "@fluentui/react-icons";

export const Sites = () => {
  const sizeStyles = useSizeStyles();
  const flexBoxStyles = useFlexBoxStyles();
  const { execute, isLoading } = useFetchWithMsal({
    scopes: protectedResources.scopes.access_as_user,
  });

  const [error, setError] = useState<Error | undefined>(undefined);
  const [siteListData, setSiteListData] = useState<Site[] | undefined>();

  const loadData = useCallback(async () => {
    setError(undefined);
    return execute("GET", `${protectedResources.sitesEndpoint}`)
      .then((response) => {
        if (response !== undefined) {
          setSiteListData(response.data as never as Site[] | undefined);
        }
      })
      .catch((error: Error) => {
        setError(error);
      });
  }, [execute]);

  useEffect(() => {
    loadData().then(() => {});
  }, [loadData]);

  return (
    <Stack className={sizeStyles.fullWidth}>
      <Stack horizontal className={`${flexBoxStyles.justifySpaceBetween} ${flexBoxStyles.alignItemsCenter} ${sizeStyles.marginBottom10}`}>
        <h2 className={sizeStyles.noMargin}>Werkomgevingen</h2>
        <Toolbar>
          <ToolbarButton aria-label="Refresh" title="De lijst met werkomgevingen opnieuw laden" appearance="subtle" icon={<ArrowClockwise16Regular />} onClick={() => loadData()}>
            Verversen
          </ToolbarButton>
        </Toolbar>
      </Stack>
      {error ? (
        <>
          <MessageBar intent="error" className={sizeStyles.marginBottom20}>
            <MessageBarBody>{error?.message}</MessageBarBody>
          </MessageBar>
        </>
      ) : (
        <></>
      )}
      {isLoading ? <Spinner label="Bezig met ophalen..." /> : <></>}
      {!isLoading && !error ? <>{siteListData ? <SiteList items={siteListData} /> : <></>}</> : <></>}
    </Stack>
  );
};
