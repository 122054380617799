import { Stack } from "../components/Stack";
import { useCallback, useEffect, useState } from "react";
import { useFlexBoxStyles, useSizeStyles } from "../rootStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { protectedResources } from "../msalConfig";
import { useFetchWithMsal } from "../hooks/useFetchWithMsal";
import { MessageBar, MessageBarBody, Spinner, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { ArrowClockwise16Regular, ArrowUpload16Regular, HandDraw16Regular, LinkMultipleRegular } from "@fluentui/react-icons";
import { FileList } from "../components/FileList";
import { ApiError, FileItem, LinkForm } from "../types/endpoints";
import { FileUploadPanel } from "../components/FileUploadPanel";
import { LinkCreationPanel } from "../components/LinkCreationPanel";
import { FileListCustomizePanel } from "../components/FileListCustomizePanel";
import { ApiErrorMessageBar } from "../components/ApiErrorMessageBar";

export const Files = () => {
  const sizeStyles = useSizeStyles();
  const flexBoxStyles = useFlexBoxStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { execute, isLoading } = useFetchWithMsal({ scopes: protectedResources.scopes.access_as_user });

  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [fileListData, setFileListData] = useState<FileItem[] | undefined>();
  const [uploadedFile, setUploadedFile] = useState<FileItem | undefined>();
  const [fileUploadPanelOpen, setFileUploadPanelOpen] = useState<boolean>(false);
  const [linkCreationPanelOpen, setLinkCreationPanelOpen] = useState<boolean>(false);
  const [Link, setLink] = useState<LinkForm | undefined>();
  const [customizePanelOpen, setCustomizePanelOpen] = useState<boolean>(false);

  const handleCustomizePanelClose = (newSiteUrl?: string, newDocumentSetName?: string) => {
    setCustomizePanelOpen(false);
    if (newSiteUrl !== undefined && newDocumentSetName !== undefined) {
      state.site.url = newSiteUrl;
      state.documentSet.naam = newDocumentSetName;

      navigate("/testen/bestanden", { state: { site: { url: newSiteUrl, naam: "Custom" }, documentSet: { naam: newDocumentSetName } } });
      loadData().then(() => {});
    }
  };

  const handleFileUploadPanelClose = (addedFile?: FileItem) => {
    setFileUploadPanelOpen(false);
    if (addedFile) {
      setUploadedFile(addedFile);
      loadData();
    }
  };

  const handleLinkCreationPanelClose = (newLink?: LinkForm) => {
    setLinkCreationPanelOpen(false);
    if (newLink) {
      setLink(newLink);
      loadData();
    }
  };

  const loadData = useCallback(async () => {
    if (!state || !state.site?.url || !state.documentSet?.naam) {
      return;
    }

    setError(undefined);

    try {
      const response = await execute("GET", `${protectedResources.filesEndpoint}?siteUrl=${encodeURIComponent(state.site.url)}&documentSetName=${encodeURIComponent(state.documentSet.naam)}`);
      if (response !== undefined) {
        setFileListData(response.data as never as FileItem[]);
      }
    }
    catch(error: any) {
      setError(error.cause || error.message);
    }
  }, [execute, state]);

  useEffect(() => {
    loadData().then(() => {});
  }, [loadData]);

  if (!state || !state.site || !state.documentSet) {
    navigate("/");
    return <></>;
  }

  return (
    <>
      <Stack className={sizeStyles.fullWidth}>
        <Stack horizontal className={`${flexBoxStyles.justifySpaceBetween} ${flexBoxStyles.alignItemsCenter} ${sizeStyles.marginBottom10}`}>
          <h2 className={sizeStyles.noMargin}>Bestanden</h2>
          <Toolbar>
            <ToolbarButton appearance="primary" icon={<ArrowUpload16Regular />} title="Een nieuwe bestand uploaden" onClick={() => setFileUploadPanelOpen(true)}>
              Upload
            </ToolbarButton>
            <ToolbarButton appearance="subtle" icon={<LinkMultipleRegular />} title="Een nieuwe link aanmaken" onClick={() => setLinkCreationPanelOpen(true)}>
              Link aanmaken
            </ToolbarButton>
            <ToolbarButton title="De lijst met bestanden ophalen met custom parameters" appearance="subtle" icon={<HandDraw16Regular />} onClick={() => setCustomizePanelOpen(true)}>
              Customiseren
            </ToolbarButton>
            <ToolbarButton aria-label="Refresh" title="De lijst met bestanden opnieuw laden" appearance="subtle" icon={<ArrowClockwise16Regular />} onClick={loadData}>
              Verversen
            </ToolbarButton>
          </Toolbar>
        </Stack>
        {error ? <ApiErrorMessageBar apiError={error} /> : <></> }       
        {uploadedFile ? (
          <MessageBar intent="success" className={sizeStyles.marginBottom20}>
            <MessageBarBody>
              {uploadedFile.naam} is succesvol geüpload (Document-ID: {uploadedFile.documentId}, URL: {uploadedFile.url})
            </MessageBarBody>
          </MessageBar>
        ) : (
          <></>
        )}
        {Link ? (
          <MessageBar intent="success" className={sizeStyles.marginBottom20}>
            <MessageBarBody>
              {Link.naam} is succesvol geüpload (Document-ID: {Link.documentId}, URL: {Link.koppelingUrl})
            </MessageBarBody>
          </MessageBar>
        ) : (
          <></>
        )}
        {isLoading ? <Spinner label="Bezig met ophalen..." /> : <></>}
        {!isLoading && fileListData ? <FileList items={fileListData} onError={(error) => setError(error)} /> : <></>}
      </Stack>
      <FileUploadPanel show={fileUploadPanelOpen} siteUrl={state.site.url} documentSetName={state.documentSet.naam} onClose={handleFileUploadPanelClose} />
      <LinkCreationPanel show={linkCreationPanelOpen} siteUrl={state.site.url} documentSetName={state.documentSet.naam} onClose={handleLinkCreationPanelClose} />
      <FileListCustomizePanel show={customizePanelOpen} siteUrl={state.site.url} onClose={handleCustomizePanelClose} documentSetName={state.documentSet.naam} />
    </>
  );
};
