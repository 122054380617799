import React, { useState } from "react";
import { ArrowDownloadFilled } from "@fluentui/react-icons";
import { protectedResources } from "../msalConfig";
import { useFetchWithMsal } from "../hooks/useFetchWithMsal";
import { Stack } from "./Stack";
import { SpinnerButton } from "./SpinnerButton";
import { ApiError } from "../types/endpoints";

interface IFileDownloadActionProps {
  fileUrl: string;
  onError: (error?: ApiError) => void;
}

export const FileDownloadAction: React.FC<IFileDownloadActionProps> = (props: IFileDownloadActionProps) => {
  const { onError, fileUrl } = props;
  const { execute } = useFetchWithMsal({ scopes: protectedResources.scopes.access_as_user });

  const fileNameFromPath = (url: string) => {
    return new URL(url).pathname.split("/").pop() || "document.docx";
  };

  const handleDownload = async () => {
    try {
      onError();

      const response = await execute("GET", `${protectedResources.fileEndpoint}?bestandsUrl=${encodeURIComponent(fileUrl)}`, undefined, undefined);

      if (!response) {
        throw new Error("No response received");
      }

      const fileName = fileNameFromPath(fileUrl);
      const url = window.URL.createObjectURL(new Blob([response], { type: "application/octet-stream" }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err: any) {
      onError(err.cause);
    }
  };

  return (
    <Stack>
      <SpinnerButton icon={<ArrowDownloadFilled />} text="Download" textWhileLoading="Downloading..." buttonProps={{ size: "medium" }} onClick={handleDownload} />
    </Stack>
  );
};
