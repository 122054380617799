import { DocumentSetList } from "../components/DocumentSetList";
import { Stack } from "../components/Stack";
import { useCallback, useEffect, useState } from "react";
import { useFlexBoxStyles, useSizeStyles } from "../rootStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiError, DocumentSetItem } from "../types/endpoints";
import { protectedResources } from "../msalConfig";
import { useFetchWithMsal } from "../hooks/useFetchWithMsal";
import { MessageBar, MessageBarBody, Spinner, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { Add16Regular, ArrowClockwise16Regular, HandDraw16Regular } from "@fluentui/react-icons";
import { DocumentSetCreationPanel } from "../components/DocumentSetCreationPanel";
import { DocumentSetListCustomizePanel } from "../components/DocumentSetListCustomizePanel";
import { ApiErrorMessageBar } from "../components/ApiErrorMessageBar";

export const DocumentSets = () => {
  const sizeStyles = useSizeStyles();
  const flexBoxStyles = useFlexBoxStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [customizePanelOpen, setCustomizePanelOpen] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [documentSetListData, setDocumentSetListData] = useState<DocumentSetItem[] | undefined>();
  const [createdDocSet, setCreatedDocSet] = useState<DocumentSetItem | undefined>();

  const { execute, isLoading } = useFetchWithMsal({ scopes: protectedResources.scopes.access_as_user });

  function handleCustomizePanelClose(newSiteUrl?: string) {
    setCustomizePanelOpen(false);
    if (newSiteUrl !== undefined) {
      state.site.url = newSiteUrl;
      navigate("/testen/documentensets", { state: { site: { url: newSiteUrl, naam: "Custom" } } });
      loadData().then(() => {});
    }
  }

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleDocumentSetCreationPanelClose = (newDocumentSet?: DocumentSetItem) => {
    setIsDrawerOpen(false);
    if (newDocumentSet) {
      setCreatedDocSet(newDocumentSet);
      loadData().then(() => {});
    }
  };

  const loadData = useCallback(async () => {
    if (!state || !state.site?.url) {
      return;
    }

    setError(undefined);
    try {
      await execute("GET", `${protectedResources.documentSetsEndpoint}?siteUrl=${encodeURIComponent(state.site.url)}`).then((response) => {
        if (response !== undefined) {
          setDocumentSetListData(response.data as never as DocumentSetItem[] | undefined);
        }
      });
    } catch (error: any) {
      setError(error.cause);
    }
  }, [execute, state]);

  useEffect(() => {
    loadData().then(() => {});
  }, [loadData]);

  if (!state || !state.site) {
    navigate("/");
    return <></>;
  }

  return (
    <>
      <Stack className={sizeStyles.fullWidth}>
        <Stack horizontal className={`${flexBoxStyles.justifySpaceBetween} ${flexBoxStyles.alignItemsCenter} ${sizeStyles.marginBottom10}`}>
          <h2 className={sizeStyles.noMargin}>Documentensets</h2>
          <Toolbar>
            <ToolbarButton appearance="primary" icon={<Add16Regular />} title="Een nieuwe documentenset maken" onClick={openDrawer}>
              Nieuw
            </ToolbarButton>
            <ToolbarButton title="De lijst met documentensets opnieuw laden" appearance="subtle" icon={<ArrowClockwise16Regular />} onClick={() => loadData()}>
              Verversen
            </ToolbarButton>
            <ToolbarButton title="De lijst met documentensets ophalen met custom parameters" appearance="subtle" icon={<HandDraw16Regular />} onClick={() => setCustomizePanelOpen(true)}>
              Customiseren
            </ToolbarButton>
          </Toolbar>
        </Stack>
        {createdDocSet ? (
          <>
            <MessageBar intent="success" className={sizeStyles.marginBottom20}>
              <MessageBarBody>
                {createdDocSet.naam} is succesvol geüpload (Document-ID: {createdDocSet.documentId}, URL: {createdDocSet.url})
              </MessageBarBody>
            </MessageBar>
          </>
        ) : (
          <></>
        )}
        {error ? <ApiErrorMessageBar apiError={error} /> : <></> }
        {isLoading ? <Spinner label="Bezig met ophalen..." /> : <></>}
        {!isLoading && !error ? <>{documentSetListData ? <DocumentSetList items={documentSetListData} /> : <></>}</> : <></>}
        {<DocumentSetCreationPanel siteUrl={state.site.url} isOpen={isDrawerOpen} onClose={handleDocumentSetCreationPanelClose} />}
        <DocumentSetListCustomizePanel show={customizePanelOpen} siteUrl={state.site.url} onClose={handleCustomizePanelClose} />
      </Stack>
    </>
  );
};
