import { useState, useCallback } from "react";
import { InteractionType, RedirectRequest, SsoSilentRequest } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";

type DataType = "json" | "formdata";

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {RedirectRequest} msalRequest
 * @returns
 */
export const useFetchWithMsal = (msalRequest: RedirectRequest) => {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState(null);
  const account = instance.getActiveAccount();

  const { result, error: msalError } = useMsalAuthentication(InteractionType.Silent, {
    ...msalRequest,
    account: account,
    redirectUri: "/redirect",
  } as SsoSilentRequest);

  const getResponseContent = async (response: Response, contentType: string | null): Promise<any> => {
    try {
      if (contentType === "application/octet-stream") {
        return await response.blob();
      } else {
        return await response.json();
      }
    } catch (e) {
      return undefined;
    }
  };

  /**
   * Execute a fetch request with the given options
   * @param {string} method: GET, POST, PUT, DELETE
   * @param {String} endpoint: The endpoint to call
   * @param {Object} data: The data to send to the endpoint, if any
   * @param {string} dataType: The data type of the request. Default is JSON
   * @returns JSON response
   */
  const execute = async (method: "GET" | "POST", endpoint: string, data: unknown | FormData = null, dataType: DataType = "json") => {
    if (msalError) {
      setError(msalError);
      return;
    }

    if (result) {
      try {
        const headers = new Headers();
        const bearer = `Bearer ${result.accessToken}`;
        headers.append("Authorization", bearer);

        if (data !== undefined && dataType === "json") headers.append("Content-Type", "application/json");

        let options = {
          method: method,
          headers: headers,
          body: data ? (dataType === "json" ? JSON.stringify(data) : (data as FormData)) : null,
        };

        setIsLoading(true);

        const response = await fetch(endpoint, options);
        const contentType = await response.headers.get("content-type");
        const responseContent = await getResponseContent(response, contentType);

        if (!response.ok) {
          if (!responseContent) {
            throw new Error("De response inhoud kon niet uitgelezen worden.");
          }

          var errorDetails = responseContent as ErrorDetails;
          throw responseContent.error?.message ? new Error(responseContent.error.message, { cause: responseContent.error }) : new Error(errorDetails.details, { cause: errorDetails });
        }

        setData(responseContent);
        setIsLoading(false);

        return responseContent;
      } catch (e: any) {
        if (console?.error) {
          console.error(error);
        }
        setError(e);
        setIsLoading(false);
        throw e;
      }
    }
  };

  return {
    isLoading,
    data,
    execute: useCallback(execute, [result, msalError]), // to avoid infinite calls when inside a `useEffect` (do not include error)
  };
};
