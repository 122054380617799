import { Breadcrumb, BreadcrumbButton, BreadcrumbDivider, BreadcrumbItem, makeStyles, Text } from "@fluentui/react-components";
import { PropsWithChildren } from "react";
import { Link, useLocation, useMatches, useNavigate } from "react-router-dom";
import { Archive16Regular, Globe16Regular, Home16Regular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  pageContainer: {
    padding: "20px",
  },
  breadCrumbContainer: {
    padding: "0 10px",
    marginBottom: "2px",
    borderBottom: "1px solid #f2f2f2",
  },
  ribbon: {
    height: "50px",
    padding: "0 20px",
    color: "white",
    display: "flex",
    alignItems: "center",
    background: appSettings.Styles.SuiteBarColor,
  },
  ribbonTitle: {
    fontWeight: "normal",
    fontSize: "18px",
    margin: 0,
  },
  breadCrumbText: {
    display: "block",
    maxWidth: "200px",
  },
  logo: {
    marginRight: '25px'
  }
});

export function PageLayout(props: PropsWithChildren<{}>): JSX.Element {
  const styles = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const matches = useMatches();
  const crumbs = matches
    .filter((match: any) => Boolean(match.handle?.breadcrumbTitle))
    .map((match) => {
      return { path: match.pathname, title: (match as any).handle.breadcrumbTitle };
    });
  const mainCrumb = crumbs[0];

  const showSitesCrumb = mainCrumb.path === "/testen/werkomgevingen" || state?.site !== undefined;
  const showDocSetsCrumb = mainCrumb.path === "/testen/documentensets" || state?.documentSet !== undefined;
  const showDocSetCrumb = state?.documentSet !== undefined;

  return (
    <>
      <div className={styles.ribbon}>
        <Link to="/"><img className={styles.logo} src="logo.png" alt="Provincie Noord-Holland" /></Link>
        <h1 className={styles.ribbonTitle}>IOS API Testapplicatie</h1>
      </div>
      <div className={styles.breadCrumbContainer}>
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbButton icon={<Home16Regular />} onClick={() => navigate("/")}>
              Home
            </BreadcrumbButton>
          </BreadcrumbItem>
          {showSitesCrumb ? (
            <>
              <BreadcrumbDivider />
              <BreadcrumbItem>
                <BreadcrumbButton icon={<Globe16Regular />} onClick={() => navigate("/testen/werkomgevingen")}>
                  Werkomgevingen
                </BreadcrumbButton>
              </BreadcrumbItem>
            </>
          ) : (
            <></>
          )}
          {showDocSetsCrumb ? (
            <>
              <BreadcrumbDivider />
              <BreadcrumbItem>
                <BreadcrumbButton>
                  <Text truncate wrap={false} className={styles.breadCrumbText}>
                    {state?.site?.naam}
                  </Text>
                </BreadcrumbButton>
              </BreadcrumbItem>

              <BreadcrumbDivider />
              <BreadcrumbItem>
                <BreadcrumbButton icon={<Archive16Regular />} onClick={() => navigate("/testen/documentensets", { state: { site: state.site } })}>
                  Documentensets
                </BreadcrumbButton>
              </BreadcrumbItem>
            </>
          ) : (
            <></>
          )}
          {showDocSetCrumb ? (
            <>
              <BreadcrumbDivider />
              <BreadcrumbItem>
                <BreadcrumbButton>
                  <Text truncate wrap={false} className={styles.breadCrumbText}>
                    {state?.documentSet?.naam}
                  </Text>
                </BreadcrumbButton>
              </BreadcrumbItem>
            </>
          ) : (
            <></>
          )}
        </Breadcrumb>
      </div>
      <div className={styles.pageContainer}>{props.children}</div>
    </>
  );
}
