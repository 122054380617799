import { MessageBar, MessageBarBody, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, makeStyles } from "@fluentui/react-components";
import { ApiError, FileItem } from "../types/endpoints";
import { FileDownloadAction } from "./FileDownloadAction";
import { Stack } from "./Stack";
import { DocumentPdf16Filled, Document16Filled, Image16Filled, FolderZip16Filled } from "@fluentui/react-icons";

const columns = [
  { columnKey: "icon", label: "Type", size: "small" },
  { columnKey: "Bestandsnaam", label: "Bestandsnaam" },
  { columnKey: "titel", label: "Titel" },
  { columnKey: "url", label: "URL" },
  { columnKey: "acties", label: "Acties" },
  { columnKey: "docId", label: "Document-id" },
];

interface IFileListProps {
  items: FileItem[];
  onError: (error?: ApiError) => void;
}

const useStyles = makeStyles({
  columnSmall: {
    width: "50px",
  },
});

export const FileList = (props: IFileListProps) => {
  const { items, onError} = props;
  const styles = useStyles();

  const getFileIcon = (fileName: string) => {
    const fileExtension = fileName.split(".").pop()?.toLowerCase();
    switch (fileExtension) {
      case "pdf":
        return <DocumentPdf16Filled />;
      case "doc":
      case "docx":
        return <Document16Filled />;
      case "xls":
      case "xlsx":
        return <Document16Filled />;
      case "ppt":
      case "pptx":
        return <Document16Filled />;
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return <Image16Filled />;
      case "zip":
        return <FolderZip16Filled />;
      default:
        return <Document16Filled />;
    }
  };

  return items ? (
    <Table>
      <TableHeader>
        <TableRow>
          {columns.map((column) => {
            const className = column.size === "small" ? styles.columnSmall : undefined;

            return (
              <TableHeaderCell className={className} key={column.columnKey}>
                {column.label}
              </TableHeaderCell>
            );
          })}
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item, index) => (
          <TableRow key={index}>
            <TableCell>
              <TableCellLayout truncate>
                <Stack>{getFileIcon(item.naam)}</Stack>
              </TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout truncate title={item.naam}>
                {item.naam}
              </TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout truncate title={item.titel}>
                {item.titel}
              </TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout truncate title={item.url}>
                {item.url}
              </TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout truncate>
                <FileDownloadAction fileUrl={item.url} onError={onError} />
              </TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout truncate title={item.documentId}>
                {item.documentId}
              </TableCellLayout>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <MessageBar>
      <MessageBarBody>Geen items gevonden</MessageBarBody>
    </MessageBar>
  );
};
