import { MessageBar } from "@fluentui/react-components";
import { ApiError } from "../types/endpoints";
import { useSizeStyles } from "../rootStyles";

export interface IApiErrorMessageBarProps {
    apiError: ApiError;
}

export const ApiErrorMessageBar: React.FC<IApiErrorMessageBarProps> = (props: IApiErrorMessageBarProps) => {
    const { apiError } = props;
    const sizeStyles = useSizeStyles();
  
    return (
        <MessageBar intent="error" className={sizeStyles.marginBottom20}>
        {apiError.foutcode} - {apiError.titel} - {apiError.details}
        <>
          { apiError.errorDetails && apiError.errorDetails.length > 0 ? <>
            <> - Details </>
            { apiError.errorDetails.map((error) => <> - {error}</>) }
          </> : 
          <></> }
        </>
      </MessageBar>
    );
  };
  