import { Drawer, DrawerHeader, DrawerHeaderTitle, Button, DrawerBody, Field, Input, Checkbox, Combobox, OptionGroup, Option, DrawerFooter, Spinner } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { Stack } from "./Stack";
import { isNullOrEmpty } from "../util";
import { useFetchWithMsal } from "../hooks/useFetchWithMsal";
import { protectedResources } from "../msalConfig";
import { ApiError, FileItem } from "../types/endpoints";
import { SpinnerButton } from "./SpinnerButton";
import { ApiErrorMessageBar } from "./ApiErrorMessageBar";

interface IFileUploadPanel {
  show: boolean;
  siteUrl: string;
  documentSetName: string;
  onClose: (createdFile?: FileItem) => void;
}

export const FileUploadPanel = (props: IFileUploadPanel) => {
  const { siteUrl, documentSetName, show, onClose } = props;
  const [isFetching, setIsFetching] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [documentTypeList, setDocumentTypeList] = useState<string[]>([]);
  const [enablePassedInFields, setEnablePassedInFields] = useState<boolean>(false);
  const [siteUrlFieldValue, setSiteUrlFieldValue] = useState<string>(siteUrl);
  const [docSetFieldValue, setDocSetFieldValue] = useState<string>(documentSetName);
  const [titleFieldValue, setTitleFieldValue] = useState<string>("");
  const [referenceFieldValue, setReferenceFieldValue] = useState<string>("");
  const [relatedAppFieldValue, setRelatedAppFieldValue] = useState<string>("");
  const [documentTypeFieldValue, setDocumentTypeFieldValue] = useState<string>("");
  const [fileFieldValue, setFileFieldValue] = useState<File | undefined>();
  const [error, setError] = useState<ApiError | undefined>();
  const relatedApplications = ["Anders - Overige", "ContractX", "iBabs", "Kofax", "M365", "Melvin", "Meridian", "OpenWave", "P8", "Pleio", "Relatics", "SAP", "SharePoint", "Tenderned", "Verseon", "VISI", "Xential"];

  const { execute, isLoading } = useFetchWithMsal({
    scopes: protectedResources.scopes.access_as_user,
  });

  const closePanel = (uploadedFile?: FileItem) => {
    setSiteUrlFieldValue(siteUrl);
    setDocSetFieldValue(documentSetName);
    setTitleFieldValue("");
    setReferenceFieldValue("");
    setRelatedAppFieldValue("");
    setDocumentTypeFieldValue("");
    setFileFieldValue(undefined);
    setEnablePassedInFields(false);
    setError(undefined);

    onClose(uploadedFile);
  };

  const submit = async (): Promise<void> => {
    setError(undefined);
    const formData = new FormData();

    if (!isNullOrEmpty(titleFieldValue)) {
      formData.append("titel", titleFieldValue!);
    }
    if (!isNullOrEmpty(siteUrlFieldValue)) {
      formData.append("siteUrl", siteUrlFieldValue!);
    }
    if (!isNullOrEmpty(docSetFieldValue)) {
      formData.append("documentenset", docSetFieldValue!);
    }
    if (!isNullOrEmpty(relatedAppFieldValue)) {
      formData.append("gerelateerdeApplicatie", relatedAppFieldValue!);
    }
    if (!isNullOrEmpty(documentTypeFieldValue)) {
      formData.append("documenttype", documentTypeFieldValue!);
    }
    if (!isNullOrEmpty(referenceFieldValue)) {
      formData.append("kenmerk", referenceFieldValue!);
    }
    if (fileFieldValue !== undefined) {
      formData.append("bestand", fileFieldValue);
    }

    try {
      const uploadedFile = await execute("POST", protectedResources.filesEndpoint, formData, "formdata");

      closePanel(uploadedFile);
    } catch (error: any) {
      setError(error.cause);
    }
  };

  useEffect(() => {
    if (show && !isDataFetched) {
      const fetchData = async () => {
        setIsFetching(true);
        try {
          const endpoints: { url: string; setter: (data: any) => void }[] = [
            {
              url: `${protectedResources.documentTypeEndpoint}`,
              setter: setDocumentTypeList,
            },
          ];

          const getAPIQueue = async (endpoints: { url: string; setter: (data: any) => void }[]) => {
            for (let i = 0; i < endpoints.length; i++) {
              const { url, setter } = endpoints[i];
              try {
                const response = await execute("GET", url);
                setter(response);
              } catch (error: any) {
                console.error(error.cause);
              }
            }
          };

          await getAPIQueue(endpoints);
          setIsDataFetched(true);
        } catch (error) {
          console.error(error);
        } finally {
          setIsFetching(false);
        }
      };

      fetchData();
    }
  }, [execute, isDataFetched, show]);

  return (
    <>
      <Drawer separator open={show} onOpenChange={(_) => closePanel()} position="end" size="medium">
        <DrawerHeader>
          <DrawerHeaderTitle action={<Button appearance="subtle" icon={<Dismiss24Regular />} onClick={() => closePanel()} />}>Bestand uploaden</DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          {isFetching ? (
            <Spinner size="large" />
          ) : (
            <Stack gap={10}>
              {error ? <ApiErrorMessageBar apiError={error} /> : <></>}
              <Checkbox label="Velden met standaardwaarden inschakelen" defaultChecked={enablePassedInFields} onChange={(_, data) => setEnablePassedInFields(data.checked === true)} />

              <Field required label="Bestand">
                <input required type="file" onChange={(ev) => setFileFieldValue(ev.target.files ? ev.target.files[0] : undefined)} disabled={isLoading} />
              </Field>

              <Field required label="Titel">
                <Input required autoComplete="off" type="text" value={titleFieldValue} onChange={(_, data) => setTitleFieldValue(data.value)} disabled={isLoading} />
              </Field>

              <Field required label="Naam documentenset">
                <Input required autoComplete="off" type="text" disabled={!enablePassedInFields || isLoading} value={docSetFieldValue} onChange={(_, data) => setDocSetFieldValue(data.value)} />
              </Field>

              <Field required label="Site Url">
                <Input required autoComplete="off" type="url" disabled={!enablePassedInFields || isLoading} value={siteUrlFieldValue} onChange={(_, data) => setSiteUrlFieldValue(data.value)} />
              </Field>

              <Field required label="Gerelateerde applicatie">
                <Combobox required freeform autoComplete="off" value={relatedAppFieldValue} onChange={(ev) => setRelatedAppFieldValue(ev.target.value)} onOptionSelect={(_, data) => setRelatedAppFieldValue(data.optionText || "")} placeholder="Selecteer applicatie" disabled={isLoading}>
                  <OptionGroup>
                    {relatedApplications.map((option) => (
                      <Option key={option}>{option}</Option>
                    ))}
                  </OptionGroup>
                </Combobox>
              </Field>

              <Field required label="Kenmerk">
                <Input required autoComplete="off" type="text" value={referenceFieldValue} onChange={(_, data) => setReferenceFieldValue(data.value)} disabled={isLoading} />
              </Field>

              <Field label="Documenttype">
                <Combobox freeform autoComplete="off" value={documentTypeFieldValue} onChange={(ev) => setDocumentTypeFieldValue(ev.target.value)} onOptionSelect={(_, data) => setDocumentTypeFieldValue(data.optionText || "")} placeholder="Selecteer documenttype" disabled={isLoading}>
                  <OptionGroup>
                    {documentTypeList.map((option) => (
                      <Option key={option}>{option}</Option>
                    ))}
                  </OptionGroup>
                </Combobox>
              </Field>
            </Stack>
          )}
        </DrawerBody>
        <DrawerFooter>
          <SpinnerButton text="Uploaden" buttonProps={{ appearance: "primary", size: "large" }} textWhileLoading="Bezig met uploaden" onClick={async () => await submit()} />
          <Button onClick={() => closePanel()} size="large" disabled={isLoading}>
            Annuleren
          </Button>
        </DrawerFooter>
      </Drawer>
    </>
  );
};
