import { makeStyles } from "@fluentui/react-components";

export const useFlexBoxStyles = makeStyles({
  flex1: {
    flex: 1,
  },
  gap10: {
    gap: "10px",
  },
  gap20: {
    gap: "20px",
  },
  justifySpaceBetween: {
    justifyContent: "space-between",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
});

export const useSizeStyles = makeStyles({
  marginBottom20: {
    marginBottom: "20px",
  },
  marginBottom10: {
    marginBottom: "10px",
  },
  marginLeft20: {
    marginLeft: "20px",
  },
  marginLeft40: {
    marginLeft: "40px",
  },
  fullWidth: {
    width: "100% !important",
  },
  fullHeight: {
    height: "100%",
  },
  maxWidth600: {
    maxWidth: "600px",
  },
  noMargin: {
    margin: "0px",
  },
  textUnderline: {
    textDecorationLine: "underline",
  },
});
