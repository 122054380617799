import { Drawer, DrawerHeader, DrawerHeaderTitle, Button, DrawerBody, Field, Input, DrawerFooter } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { useState } from "react";
import { Stack } from "./Stack";

interface IDocumentSetListCustomizePanel {
  show: boolean;
  siteUrl: string;
  onClose: (newSiteUrl?: string) => void;
}

export const DocumentSetListCustomizePanel = (props: IDocumentSetListCustomizePanel) => {
  const { siteUrl, show, onClose } = props;
  const [siteUrlFieldValue, setSiteUrlFieldValue] = useState<string>(siteUrl);

  const closePanel = (newSiteUrl?: string) => {
    if (newSiteUrl !== undefined) {
      onClose(newSiteUrl);
    } else {
      onClose();
    }

    setSiteUrlFieldValue(siteUrl);
  };

  const submit = () => {
    closePanel(siteUrlFieldValue);
  };

  return (
    <>
      <Drawer separator open={show} onOpenChange={(_) => closePanel()} position="end" size="medium">
        <DrawerHeader>
          <DrawerHeaderTitle action={<Button appearance="subtle" icon={<Dismiss24Regular />} onClick={() => closePanel()} />}>Customiseren</DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <Stack gap={10}>
            <p>Met het onderstaande invoerveld kan er gekozen worden om een alternatieve site URL uit te proberen om te kijken hoe het API reageert.</p>
            <Field required={true} label="Site Url">
              <Input required={true} autoComplete="off" type="url" defaultValue={siteUrlFieldValue} onChange={(_, data) => setSiteUrlFieldValue(data.value)} />
            </Field>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <Button appearance="primary" size="large" onClick={() => submit()}>
            Verzenden
          </Button>
          <Button onClick={() => closePanel()} size="large">
            Annuleren
          </Button>
        </DrawerFooter>
      </Drawer>
    </>
  );
};
